<template>
	<Dialog :dialog="checkoutDialog" :dialogWidth="dialogWidth">
		<template v-slot:title>
			<v-layout>
				<v-flex>Select {{ fileTab }}</v-flex>
				<v-flex class="text-right">
					<v-btn
						icon
						:disabled="dataLoading"
						class="mx-2 custom-grey-border custom-bold-button"
						v-on:click="$emit('close', true)"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-flex>
			</v-layout>
		</template>
		<template v-slot:body>
			<v-container fluid>
				<template>
					<v-tabs
						v-model="fileTab"
						background-color="transparent"
						centered
						color="blue darken-4"
						fixed-tabs
						class="custom-tab-transparent"
					>
						<template>
							<v-tab class="font-size-16 font-weight-600 px-8" href="#assets"> Assets </v-tab>
							<v-tab class="font-size-16 font-weight-600 px-8" href="#asset-stock" v-if="!assetOnly">
								Assets Stocks
							</v-tab>
						</template>
					</v-tabs>
					<v-tabs-items v-model="fileTab">
						<v-tab-item :value="'assets'">
							<v-row>
								<template>
									<v-col md="12">
										<perfect-scrollbar
											:options="{ suppressScrollX: true }"
											class="scroll"
											style="max-height: 60vh; position: static"
										>
											<v-list subheader three-line>
												<v-skeleton-loader
													v-if="dataLoading"
													class="custom-skeleton"
													type="list-item-avatar-two-line"
												>
												</v-skeleton-loader>
												<template v-else>
													<template v-if="assetList.length > 0">
														<v-list-item
															class="border border-secondary mb-3"
															v-on:click="selectAsset(data)"
															v-for="(data, index) in assetList"
															:key="index"
															link
														>
															<v-list-item-avatar>
																<ImageTemplate circle :src="data.image"></ImageTemplate>
															</v-list-item-avatar>
															<v-list-item-content>
																<v-list-item-title class="font-size-16">
																	<Chip color="blue darken-4" :text="data.barcode"></Chip>
																</v-list-item-title>
																<v-list-item-subtitle class="font-size-14">
																	<b>{{ data.name }}</b>
																</v-list-item-subtitle>
															</v-list-item-content>
															<v-list-item-action class="margin-auto">
																<v-list-item-action-text class="font-size-16"
																	><b>Cost</b> {{ data.cost_price_formatted }}
																</v-list-item-action-text>
															</v-list-item-action>
														</v-list-item>
													</template>
													<template v-else>
														<v-list-item>
															<v-list-item-content>
																<v-list-item-title class="font-size-16">No Asset(s) Found </v-list-item-title>
															</v-list-item-content>
														</v-list-item>
													</template>
												</template>
											</v-list>
										</perfect-scrollbar>
									</v-col>
								</template>
							</v-row>
						</v-tab-item>
						<v-tab-item :value="'asset-stock'" v-if="!assetOnly">
							<v-row>
								<template>
									<v-col md="12">
										<perfect-scrollbar
											:options="{ suppressScrollX: true }"
											class="scroll"
											style="max-height: 60vh; position: static"
										>
											<v-list subheader three-line>
												<v-skeleton-loader
													v-if="dataLoading"
													class="custom-skeleton"
													type="list-item-avatar-two-line"
												>
												</v-skeleton-loader>
												<template v-else>
													<template v-if="assetStockList.length > 0">
														<v-list-item
															class="border border-secondary"
															v-on:click="selectAssetStock(stockData)"
															v-for="(stockData, stockIndex) in assetStockList"
															:key="stockIndex"
															link
														>
															<v-list-item-avatar>
																<ImageTemplate circle :src="stockData.image"></ImageTemplate>
															</v-list-item-avatar>
															<v-list-item-content>
																<v-list-item-title class="font-size-16">
																	<Chip color="blue darken-4" :text="stockData.barcode"></Chip>
																</v-list-item-title>
																<v-list-item-subtitle class="font-size-14">
																	<b>{{ stockData.name }}</b>
																</v-list-item-subtitle>
															</v-list-item-content>
															<v-list-item-action class="margin-auto">
																<!-- <v-list-item-action-text class="font-size-16"
																	><b>Contact</b> - {{ stockData.contact }}
																</v-list-item-action-text> -->
																<v-list-item-action-text class="font-size-16"
																	><b>Cost</b> {{ stockData.price_add_stock_formatted }}
																</v-list-item-action-text>
															</v-list-item-action>
														</v-list-item>
													</template>
													<template v-else>
														<v-list-item>
															<v-list-item-content>
																<v-list-item-title class="font-size-16">No Asset Stock(s) Found </v-list-item-title>
															</v-list-item-content>
														</v-list-item>
													</template>
												</template>
											</v-list>
										</perfect-scrollbar>
									</v-col>
								</template>
							</v-row>
						</v-tab-item>
					</v-tabs-items>
				</template>
			</v-container>
		</template>
		<template v-slot:action>
			<v-btn
				class="mx-2 custom-grey-border custom-bold-button"
				color="blue darken-4 white--text"
				tile
				depressed
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";

export default {
	data() {
		return {
			dataLoading: false,
			fileTab: "assets",
			searchLoader: true,
			assetList: [],
			assetStockList: [],
			expenseDialog: false,
			contractor: null,
			timeoutLimit: 500,
			timeout: null,
			contractorArr: new Object(),
			createContractorDialog: false,
			contractorList: new Array(),
			dialog: false,
		};
	},
	props: {
		checkoutDialog: {
			type: Boolean,
			default: false,
		},
		dialogWidth: {
			type: Number,
			default: 768,
		},
		assetOnly: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: null,
		},
	},
	components: {
		Dialog,
		Chip,
		ImageTemplate,
	},
	watch: {
		checkoutDialog: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param) {
					this.getAssets();
				}
			},
		},
	},
	methods: {
		getAssets() {
			ApiService.setHeader();
			ApiService.get(`damage-asset-list`)
				.then(({ data }) => {
					this.assetList = data;
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.dataLoading = false;
				});
		},

		selectAsset(data) {
			this.$emit("selectRelatedTo", {
				id: data.id,
				type: "Asset",
				endpoint: "asset",
				checkout_uuid: data.checkout_uuid,
			});
		},
	},
	/*  mounted(){
      this.getAssets();
			this.getAssetStocks();
  } */
};
</script>
